<template>
  <div>
    <!-- <h1>{{form.formName}}</h1> -->
    <el-form :model="form" ref="form" label-position="top">
      <el-form-item :label="form.configList.title" :rules="makeRule(form.configList)" :prop="`configList.${form.configList.submitKey}`">
        <el-input v-if="form.configList.type=='input'" style="width:300px;" v-model="form.configList[form.configList.submitKey]" :maxlength="form.configList.textLength||Infinity"
          :placeholder="form.configList.placeholder||('请输入'+form.configList.title)"></el-input>
        <el-input v-else-if="form.configList.type=='textarea'" type="textarea" v-model="form.configList[form.configList.submitKey]" autosize
          :placeholder="form.configList.placeholder||('请输入'+form.configList.title)"></el-input>
        <el-input v-else-if="form.configList.type=='mobileTextarea'" type="textarea" style="min-width:300px;" v-model="form.configList[form.configList.submitKey]" autosize
          :placeholder="form.configList.placeholder||('请输入'+form.configList.title)"></el-input>
        <div v-else-if="form.configList.type=='radio'||form.configList.type=='industry'">
          <el-radio v-for="(radio,rindex) in form.configList.items" :label="radio.name" :value="rindex" :key="'radio'+rindex" v-model="form.configList[form.configList.submitKey]"></el-radio>
          <el-input v-if="form.configList[form.configList.submitKey]=='其他'" v-model="form.configList.other" />
        </div>
        <div v-else-if="form.configList.type=='checkbox'">
          <el-checkbox-group v-model="form.configList[form.configList.submitKey]" :max="form.configList.mulite">
            <el-checkbox :label="check.name" v-for="(check,cindex) in form.configList.items" :key="'check'+cindex">
              {{check.name}}
              <el-input v-model="form.configList.other" v-if="check.name=='其他'" />
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div v-else-if="form.configList.type=='select'">
          <el-select v-model="form.configList[form.configList.submitKey]" @change="$forceUpdate()">
            <el-option :label="check.name" v-for="(check,cindex) in form.configList.items" :value="check.name" :key="'option'+cindex"></el-option>
          </el-select>
        </div>
        <div v-else-if="form.configList.type=='switch'">
          <el-switch v-model="form.configList[form.configList.submitKey]" :active-value="1" :inactive-value="0"></el-switch>
        </div>
        <div v-else-if="form.configList.type=='upload'">
          <div class="flex-center">
            <div class="imgcontext bold m-r-10" v-for="(imgItem,imgIndex) in form.configList[form.configList.submitKey]" :key="'uploadimg'+imgIndex">
              <el-image :src="imgItem" fit="cover" class=""></el-image>
              <div class="abshover">
                <el-tooltip content="预览" placement="top" effect="light">
                  <i class="el-icon-zoom-in" @click="handlePictureCardPreview(imgItem)"></i>
                </el-tooltip>
                <el-upload accept="jpg,jpeg,png" action="#" :ref="'reupload'" :show-file-list="false" :multiple="false" :drag="false" :http-request="handleUpload" :name="`reupload.${imgIndex}`">
                  <el-tooltip content="重新上传" placement="top" effect="light">
                    <i class="el-icon-upload2"></i>
                  </el-tooltip>
                </el-upload>
                <el-tooltip content="移除" placement="top" effect="light">
                  <i class="el-icon-delete" @click="handleRemove(imgIndex)"></i>
                </el-tooltip>
              </div>
            </div>
            <el-upload action="#" :accept="form.configList.endWith" class="imgcontext bold" :name="'upload'" :show-file-list="false" :http-request="handleUpload" :ref="'upload'"
              v-if="form.configList[form.configList.submitKey].length<form.configList.count">
              <div class="upload">
                <icon-font type="icon-shangchuan-copy" />
                <div style="font-size:16px;font-weight:bold;">点击上传</div>
              </div>
            </el-upload>
            <div class=" m-l-10" style="line-height:1.5;letter-spacing:1px;color:#999999;">
              <!-- <div>
                允许上传文件类型:{{form.configList.endWith}}
              </div> -->
              <div>
                材料说明:<span v-html="form.description"></span>
              </div>
            </div>
          </div>
          <div class="m-t-10 flex-align">
            <el-button plain style="width:128px;margin:0 10px;" v-if="form.modelUrl" @click="download(form.modelUrl)">
              <icon-font style="font-size:16px;" type="icon-xiazaidaoru" /> 下载模板
            </el-button>
            <el-button type="text" style="color:#0058FF;" v-if="form.exampleUrl" @click="handlePictureCardPreview(form.exampleUrl)">查看示例</el-button>
          </div>
        </div>
        <div v-else-if="form.configList.type=='date'">
          <el-date-picker v-model="form.configList[form.configList.submitKey]" value-format="yyyy-MM-dd"></el-date-picker>
        </div>
        <div v-else-if="form.configList.type=='cascader'">
          <el-cascader v-if="form.configList.dataType=='custom'" :props="{value:'id'}" v-model="form.configList[form.configList.submitKey]" :options="form.configList.options"></el-cascader>
          <!-- <el-tree :data="item.options" :ref="`tree`+index" default-expand-all
                :props="defaultProps" show-checkbox></el-tree> -->
          <el-cascader v-else-if="form.configList.dataType=='provinces'" v-model="form.configList[form.configList.submitKey]" :props="region">
          </el-cascader>
        </div>
      </el-form-item>
    </el-form>
    <!-- <el-button type="primary" @click="submit">提交</el-button> -->
    <el-dialog v-model="dialogVisible">
      <img style="width:100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import { IconFont } from '@/utils/iconfont'
import img from '@/api/common/img'
export default {
  components: {
    IconFont,
  },
  name: 'dynamicForm',
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
      dialogImageUrl: '',
      region: {
        lazy: true,
        lazyLoad: (node, resolve) => {
          const { level, data } = node
          if (!level) {
            fetch('http://192.168.2.135:7001/provinces')
              .then((response) => {
                if (response.ok) {
                  return response.json()
                } else {
                  throw new Error(response.statusText)
                }
              })
              .then((res) => {
                resolve(res.data)
              })
          } else {
            fetch(
              `http://192.168.2.135:7001/region?pid=${data.id}&level=${
                level + 1
              }`
            )
              .then((response) => {
                if (response.ok) {
                  return response.json()
                } else {
                  throw new Error(response.statusText)
                }
              })
              .then((res) => {
                resolve(res.data)
                this.$forceUpdate()
              })
          }
        },
      },
    }
  },
  methods: {
    upload(type, formData, cb) {
      if (type == 'img') {
        img.imgWebUpload(formData).then((res) => {
          if (res) {
            if (this.ops == '') {
              cb(res.data.url)
            } else {
              console.log(this.ops)
              let newjson = [res.data.url, this.ops]
              cb(newjson)
            }
          }
        })
      } else {
        img.imgUpload(formData).then((res) => {
          if (res) {
            cb(res.data.url)
          }
        })
      }
    },
    getProvinces() {
      fetch('http://192.168.2.135:7001/provinces')
        .then((response) => {
          if (response.ok) {
            return response.json()
          } else {
            throw new Error(response.statusText)
          }
        })
        .then((res) => {
          this.region = res.data
        })
    },
    makeRule(item) {
      let rule = {}
      switch (item.type) {
        case 'input':
          rule = {
            required: item.required,
            message: '请检查输入',
            validator: (r, v, c) => {
              if (!r.required) {
                c()
              }
              let arr = r.field.split('.')
              let final = this.form[arr[0]]
              for (let i = 1; i < arr.length; i++) {
                final = final[arr[i]]
              }
              if (item.pattern) {
                let pattern = eval(`/${item.pattern}/`)
                final = pattern.test(final)
              }
              if (final) {
                c()
              } else {
                c(r.message)
              }
            },
            trigger: ['blur', 'change'],
          }
          break
        case 'textarea':
          rule = {
            required: item.required,
            message: '请检查输入',
            validator: (r, v, c) => {
              if (!r.required) {
                c()
              }
              let arr = r.field.split('.')
              let final = this.form[arr[0]]
              for (let i = 1; i < arr.length; i++) {
                final = final[arr[i]]
              }
              if (final) {
                c()
              } else {
                c(r.message)
              }
            },
            trigger: ['blur', 'change'],
          }
          break
        case 'mobileTextarea':
          rule = {
            required: item.required,
            message: '请检查输入',
            validator: (r, v, c) => {
              if (!r.required) {
                c()
              }
              let arr = r.field.split('.')
              let final = this.form[arr[0]]
              for (let i = 1; i < arr.length; i++) {
                final = final[arr[i]]
              }
              let arr2 = final.split(/\r|\n|\r\n/)
              let result = arr2.filter((e) => {
                return !/^1[3456789]\d{9}$/.test(e)
              })
              if (result.length) {
                return c('请检查输入格式')
              } else if (final) {
                c()
              } else {
                c(r.message)
              }
            },
            trigger: ['blur', 'change'],
          }
          break
        case 'switch':
          rule = {
            required: item.required,
            message: '请检查输入',
            validator: (r, v, c) => {
              c()
            },
            trigger: ['blur', 'change'],
          }
          break
        case 'radio':
          rule = {
            required: item.required,
            message: '请选择' + item.title,
            validator: (r, v, c) => {
              if (!r.required) {
                c()
              }
              let arr = r.field.split('.')
              let final = this.form[arr[0]]
              for (let i = 1; i < arr.length; i++) {
                final = final[arr[i]]
              }
              if (final !== null) {
                c()
              } else {
                c(r.message)
              }
            },
            trigger: ['blur', 'change'],
          }
          break
        case 'checkbox':
          rule = {
            required: item.required,
            message: '请选择' + item.title,
            validator: (r, v, c) => {
              if (!r.required) {
                c()
              }
              let arr = r.field.split('.')
              let final = this.form[arr[0]]
              for (let i = 1; i < arr.length; i++) {
                final = final[arr[i]]
              }
              if (final !== null) {
                c()
              } else {
                c(r.message)
              }
            },
            trigger: ['blur', 'change'],
          }
          break
        case 'select':
          rule = {
            required: item.required,
            message: '请选择' + item.title,
            validator: (r, v, c) => {
              if (!r.required) {
                c()
              }
              let arr = r.field.split('.')
              let final = this.form[arr[0]]
              for (let i = 1; i < arr.length; i++) {
                final = final[arr[i]]
              }
              if (final !== null) {
                c()
              } else {
                c(r.message)
              }
            },
            trigger: ['blur', 'change'],
          }
          break
        case 'upload':
          rule = {
            required: item.required,
            message: '请上传' + item.title,
            validator: (r, v, c) => {
              if (!r.required) {
                c()
              }
              let arr = r.field.split('.')
              let final = this.form[arr[0]]
              let item = {}
              for (let i = 1; i < arr.length; i++) {
                final = final[arr[i]]
                if (i == arr.length - 2) {
                  item = final
                }
              }
              if (final.length) {
                c()
              } else {
                c(r.message)
              }
            },
            trigger: ['blur', 'change'],
          }
          break
        case 'date':
          rule = {
            required: item.required,
            message: '请选择' + item.title,
            validator: (r, v, c) => {
              if (!r.required) {
                c()
              }
              let arr = r.field.split('.')
              let final = this.form[arr[0]]
              for (let i = 1; i < arr.length; i++) {
                final = final[arr[i]]
              }
              if (final !== null) {
                c()
              } else {
                c(r.message)
              }
            },
            trigger: ['blur', 'change'],
          }
          break
        case 'cascader':
          rule = {
            required: item.required,
            message: '请选择' + item.title,
            validator: (r, v, c) => {
              if (!r.required) {
                c()
              }
              let arr = r.field.split('.')
              let final = this.form[arr[0]]
              for (let i = 1; i < arr.length; i++) {
                final = final[arr[i]]
              }
              if (final !== null) {
                c()
              } else {
                c(r.message)
              }
            },
            trigger: ['blur', 'change'],
          }
          break
        default:
          break
      }
      rule.required = this.form.required == 0 ? true : false
      // console.log({
      //   rule
      // })
      return rule
    },
    handleUpload(event) {
      console.log(event)
      let arr = event.filename.split('.')
      let index = parseInt(arr[1])
      let form = new FormData()
      // let maxSize = 1;

      let fileType = this.form.configList.endWith.split(/\.|,|。|，/)
      let allowed = false

      for (let i of fileType) {
        // console.log(event.file.type, event.file.type.indexOf(i));
        if (~event.file.type.indexOf(i)) {
          allowed = true
          break
        }
      }
      console.log(fileType)
      if (!allowed) {
        event.onError()
        return this.$notify.error({
          message:
            '文件格式错误，允许上传文件类型：' + this.form.configList.endWith,
        })
      }

      let maxSize = this.form.configList.size * 1024 * 1024

      if (maxSize && event.file.size > maxSize) {
        event.onError()
        return this.$notify.error({
          message: '文件过大，请重新选择',
        })
      }

      form.append('file', event.file)

      this.upload('img', form, (url) => {
        if (arr[0] == 'reupload') {
          this.form.configList[this.form.configList.submitKey][arr[1]] = url
        } else {
          this.form.configList[this.form.configList.submitKey].push(url)
        }
        this.$forceUpdate()
      })
    },
    handleRemove(index, imgindex) {
      this.form.configList.value.splice(imgindex, 1)
      this.$forceUpdate()
    },
    handlePictureCardPreview(url) {
      this.dialogImageUrl = url
      this.dialogVisible = true
    },
    handleDownload(file) {
      console.log(file)
      console.log(file.raw.slice(0, 1024))
      // console.log(file.raw.slice(0,1024));
      file.raw
        .slice(0, 1024)
        .arrayBuffer()
        .then((res) => {
          console.log(res)
          localStorage.setItem('down', res)
        })
    },
    download(url) {
      window.open(url)
    },
    submit(cb) {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          // console.log(this.form);
          // this.$emit('submit',this.form)
        }
        cb(valid)
      })
    },
  },
  mounted() {
    // console.log(this.form);
  },
}
</script>

<style scoped lang="scss">
::v-deep(.el-form-item) {
  font-size: 16px;
}
::v-deep(.el-select .el-input .el-select__caret) {
  color: #0058ff;
}
::v-deep(.el-textarea__inner) {
  min-height: 80px !important;
}
::v-deep(.el-button.is-plain:hover,
.el-button.is-plain:focus,
.el-button.is-plain,
.el-button.is-plain) {
  border-color: #0058ff;
  color: #0058ff;
}
::v-deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
  background-color: #0058ff;
}
::v-deep(.el-checkbox__inner:hover) {
  border-color: #0058ff;
}
::v-deep(.el-radio__input.is-checked .el-radio__inner) {
  background-color: #0058ff;
}
::v-deep(.el-radio__inner:hover) {
  border-color: #0058ff;
}
::v-deep(.el-checkbox__input.is-checked + .el-checkbox__label) {
  color: #606266;
}
::v-deep(.el-radio__input.is-checked + .el-radio__label) {
  color: #606266;
}
.m-t-10 {
  margin-top: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.m-r-10 {
  margin-right: 10px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.imgcontext {
  width: 148px;
  height: 148px;
  position: relative;
  overflow: hidden;

  .upload {
    // line-height: 148px;
    width: 148px;
    height: 148px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    color: #0058ff;
  }

  &.bold {
    border: 1px dashed #b9b9b9;
    border-radius: 0.5rem;

    &:hover {
      .upload {
        color: #0058ff;
      }

      border: 1px dashed #0058ff;
    }
  }
}

.abshover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;

  &:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.3);
  }

  [class*='el-icon'] {
    color: #fff;
    cursor: pointer;
  }
}

.flex-center {
  display: flex;
  align-items: center;
}
</style>
